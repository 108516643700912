.drag-drop-area {
    width: 100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
      ".... inpt ...."
      ".... list rmve";
  }
  
.drag-drop-input-area {
  grid-area: inpt;
  margin-bottom: 2em;
  min-height: 30px;
  display:flex
}

.drag-drop-input-field {
  flex-grow: 1;
  border: 1.5px solid #e29578;
  padding: 7px;
}

.drag-drop-input-field:focus {
  outline: none;
}

.drag-drop-btn {
 min-width: 60px;
 border: 1.5px solid #006d77;
 color: #006d77;
 background-color: #e29578;
}

.drag-drop-list {
  grid-area: list;
}

.drag-drop-item {
  background-color: #edf6f9;
  border: 1.5px solid #e29578;
  border-radius: 20em;
  cursor: grab;
  user-select: none;
  margin-bottom: 0.5em;
  text-align: center;
}

.drag-drop-item:active {
  cursor: grabbing;
}

.drag-drop-remove-area {
  grid-area: rmve;
  opacity: 0;
}
  