.App {
  background-color: #83c5be;
  height:100vh;
  overflow: auto;
  color: #006d77;
}

h1 {
  width: 200px;
  margin: 2em auto 2em auto;
  text-align: center;
}

/* Sidebar container */
.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 200px; /* Width when expanded */
  background-color: #333;
  color: white;
  overflow-x: hidden;
  transition: width 0.3s; /* Smooth transition */
}

/* Retracted state */
.sidebar.retracted {
  width: 50px; /* Width when retracted */
}

/* Toggle button */
.toggle-button {
  
  top: 20px;
  right: -25px;
  width: 25px;
  height: 25px;
  background-color: #444;
  color: white;
  border: none;
  cursor: pointer;
}

/* Content inside the sidebar */
.content {
  padding: 20px;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
}

button {
  margin: 5px;
}
